/* eslint-disable max-lines */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, Box, Button, Link } from 'rebass/styled-components';
import { ChevronRight, X, Heart, AlertTriangle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { ProfilePicture, Message, Tag } from '@getro/rombo';
import { END } from 'redux-saga';
import { wrapper } from '../../../redux/store';
import { companyWebsiteLinkClick } from '../../../redux/actions/companyDetails';
import {
  loadDiscardCompany,
  loadFavoriteCompany,
  flagWrongDataClicked,
  claimProfileClicked,
} from '../../../redux/actions/companies';
import GetroClient from '../../../api/GetroClient';
import { JobsResultsList } from '../../../components/organisms/jobsResultsList';
import { SocialLinks } from '../../../components/molecules/socialLinks';
import SEO from '../../../components/molecules/Seo';

import {
  isAuthenticatedSelector,
  makeOrganizationAlreadyIntroduced,
  makeOrganizationIntroductionStatus,
  makeSelectDiscardCompanyIds,
  makeSelectFavoriteCompanyIds,
  makeSelectUserVerified,
} from '../../../redux/selectors/user';
import { toggleIntroductionRequestModal } from '../../../redux/actions/introductionRequestActions';
import { getSSRProtocolAndHost } from '../../../helpers/ssrHelpers';
import { toggleSignUpModal } from '../../../redux/actions/userActions';
import JobListFilters from '../../../components/organisms/jobListFilters';
import DetailsLayout from '../../../components/layouts/detailsLayout';
import {
  makeFeatureSelector,
  organizationsStringSelector,
  talentNetworkSelector,
} from '../../../redux/selectors/network';
import { NetworkSchema } from '../../../schema/network';
import { ProtocolSchema } from '../../../schema/protocol';
import { FourOFour } from '../../../components/molecules/fourOfour';
import { CompanySchema } from '../../../schema/company';
import { decryptQuery } from '../../../hooks/useEncryptedRouter';
import SentryAPI from '../../../api/SentryAPI';
import InfoItem from '../../../components/atoms/InfoItem';
import { companyStageFormatter } from '../../../helpers/stringHelper';
import useTagsVisibility from '../../../hooks/useTagsVisibility';
import useCustomFilters from '../../../hooks/useCustomFilters';
import { JOB_SENIORITY_VALUES } from '../../../constants/index';

const removeHostPrefix = (url) => {
  let result = url;
  if (result.indexOf('https://') > -1) {
    result = url.split('https://')[1];
  } else if (result.indexOf('https://')) {
    result = url.split('http://')[1];
  }
  return result;
};

const Index = ({ network, host, protocol, company }) => {
  useCustomFilters({ network });
  const dispatch = useDispatch();
  const { jobFunctions } = company;
  const isUserVerified = useSelector(makeSelectUserVerified({ network }));
  const alreadyIntroduced = useSelector(makeOrganizationAlreadyIntroduced(company));
  const introductionStatusAccepted = useSelector(makeOrganizationIntroductionStatus(company)) === 'accepted';
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const favoritedOrganizationIds = useSelector(makeSelectFavoriteCompanyIds);
  const discardedOrganizationIds = useSelector(makeSelectDiscardCompanyIds);
  const { hasIndustryTagsVisible, hasStageTagsVisible, visibleTopics } = useTagsVisibility({ network, company });

  const hasTalentNetwork = talentNetworkSelector(network);
  const organizationString = organizationsStringSelector(false, network);
  const organizationsString = organizationsStringSelector(true, network);
  const claimOrganizationProfile = makeFeatureSelector('claim_organization_profile', network);
  const { jobBoardFilters } = network;
  const { jobFunctionFilter, seniorityFilter, compensationFilter } = jobBoardFilters;

  const hideJobFunctionFilter = !jobFunctionFilter;
  const hideSeniorityFilter = !seniorityFilter;
  const hideCompensationFilter = !compensationFilter;

  const defaultQuery = { 'organization.id': [company.id] };

  const isFavorite = useMemo(
    () => company && favoritedOrganizationIds && favoritedOrganizationIds.indexOf(company.id) >= 0,
    [company, favoritedOrganizationIds],
  );

  const isDiscarded = useMemo(
    () => company && discardedOrganizationIds && discardedOrganizationIds.indexOf(company.id) >= 0,
    [company, discardedOrganizationIds],
  );

  const filterOptions = [];
  if (!hideJobFunctionFilter && jobFunctions && jobFunctions.length > 0) {
    filterOptions.push({
      order: filterOptions.length + 1,
      queryParam: 'job_functions',
      name: 'Job function',
      options: jobFunctions.map(({ name }) => ({ value: name, name })),
    });
  }
  if (!hideSeniorityFilter) {
    filterOptions.push({
      order: filterOptions.length + 1,
      queryParam: 'seniority',
      name: 'Seniority',
      options: Object.entries(JOB_SENIORITY_VALUES).map(([key, value]) => ({
        name: value,
        value: key,
      })),
    });
  }
  if (!hideCompensationFilter) {
    filterOptions.push({
      order: filterOptions.length + 1,
      queryParam: 'compensation',
      name: 'Salary',
    });
  }

  const trackClaimYourProfileClick = () => {
    dispatch(
      claimProfileClicked({
        network,
        organizationName: company.name,
        id: company.id,
        collectionName: network.name,
        collectionId: network.id,
        origin: 'organization_view_sidebar',
      }),
    );
  };

  const requestIntroduction = (e) => {
    e.preventDefault();
    dispatch(toggleIntroductionRequestModal({ organization: company, source: 'organization_profile' }));
  };

  const showOptions = (isUserVerified && hasTalentNetwork) || hasTalentNetwork || isDiscarded;
  const showSocialLinks = useMemo(
    () =>
      company.angellistUrl ||
      company.crunchbaseUrl ||
      company.facebookUrl ||
      company.instagramUrl ||
      company.linkedinUrl ||
      company.twitterUrl,
    [company],
  );

  const hasWebsite = useMemo(() => !!company.websiteUrl || !!company.domain, [company]);
  const websiteUrl = useMemo(() => company.websiteUrl || `https://${company.domain}`, [company]);
  const companySize = useMemo(() => {
    const { approxEmployees } = company;
    if (approxEmployees > 0) {
      if (approxEmployees >= 1 && approxEmployees <= 10) {
        return '1 - 10';
      }

      if (approxEmployees >= 11 && approxEmployees <= 50) {
        return '11-50';
      }

      if (approxEmployees >= 51 && approxEmployees <= 200) {
        return '51-200';
      }

      if (approxEmployees >= 201 && approxEmployees <= 1000) {
        return '201-1000';
      }

      if (approxEmployees >= 1001 && approxEmployees <= 5000) {
        return '1001-5000';
      }

      return '5001+';
    }

    return null;
  }, [company]);

  return (
    <Flex my={4} flexDirection={['column', 'column', 'row']} data-testid="company-details">
      {company && (
        <>
          <SEO network={network} title={company.name} host={host} protocol={protocol} />
          <>
            <Box data-testid="info" width={[1, 1, 1 / 4]} pl={0} pr={[0, 0, 3]}>
              <Flex flexDirection="column">
                <Flex flexDirection={['row', 'row', 'column']}>
                  <Box width="80px" sx={{ height: '80px', mb: [0, 0, 2], mr: [2, 2, 0] }}>
                    <ProfilePicture imageUrl={company.logoUrl} name={company.name} variant="square" />
                  </Box>
                  <Flex flexDirection="column" justifyContent="center">
                    <Text
                      fontWeight="600"
                      alignSelf={['center', 'center', 'unset']}
                      fontSize="28px"
                      lineHeight="1.4"
                      color="text.dark"
                    >
                      {company.name}
                    </Text>
                    {hasWebsite && (
                      <Box
                        as="a"
                        className="theme_only"
                        fontSize={1}
                        alignItems="center"
                        display="flex"
                        variant="getroLink"
                        color="text.subtle"
                        sx={{ textDecoration: 'none !important', lineHeight: '17px' }}
                        href={websiteUrl}
                        onClick={() => {
                          dispatch(
                            companyWebsiteLinkClick({
                              network,
                              organizationName: company.name,
                              id: company.id,
                              origin: 'organization_view_sidebar',
                            }),
                          );
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {removeHostPrefix(websiteUrl)}
                      </Box>
                    )}
                  </Flex>
                </Flex>

                {((isUserVerified && hasTalentNetwork) || hasTalentNetwork || isDiscarded) && (
                  <Box mt="40px" pt={3} mb={3} as="hr" variant="hr" />
                )}
                {hasTalentNetwork && (
                  <Flex mb={3}>
                    <Button
                      onClick={() => {
                        if (isAuthenticated) {
                          dispatch(loadDiscardCompany({ network, company, discarded: !isDiscarded }));
                        } else {
                          dispatch(
                            toggleSignUpModal({ network, button: 'discard', type: 'organization', id: company.id }),
                          );
                        }
                      }}
                      alignItems="center"
                      display="flex"
                      mr={2}
                      width={[1 / 2]}
                      variant="secondary"
                      px={0}
                      justifyContent="center"
                    >
                      <Box
                        mr={1}
                        variant="icon"
                        className="fa fa-close-o"
                        height="14px"
                        width="14px"
                        strokeWidth="2"
                        aria-hidden="true"
                        as={X}
                      />
                      {isDiscarded ? 'Discarded' : 'Discard'}
                    </Button>
                    <Button
                      alignItems="center"
                      display="flex"
                      ml={2}
                      width={[1 / 2]}
                      px={0}
                      justifyContent="center"
                      variant="accent"
                      onClick={() => {
                        if (isAuthenticated) {
                          dispatch(loadFavoriteCompany({ network, company, favorite: !isFavorite }));
                        } else {
                          dispatch(
                            toggleSignUpModal({ network, button: 'like', type: 'organization', id: company.id }),
                          );
                        }
                      }}
                    >
                      <Box
                        mr={1}
                        variant="icon"
                        fill={isFavorite ? 'currentColor' : 'none'}
                        className="fa fa-heart-o"
                        height="14px"
                        width="14px"
                        strokeWidth="2"
                        aria-hidden="true"
                        as={Heart}
                      />
                      {isFavorite ? 'Favorited' : 'Favorite'}
                    </Button>
                  </Flex>
                )}
                {isDiscarded && (
                  <Message type="info">
                    Discarded {organizationsString} won&apos;t appear in search results.{' '}
                    <Button
                      variant="anchorLink"
                      display="inline-block"
                      type="button"
                      sx={{ textDecoration: 'underline' }}
                      onClick={() => {
                        dispatch(loadDiscardCompany({ network, company, discarded: !isDiscarded }));
                      }}
                    >
                      Undo discard
                    </Button>
                  </Message>
                )}
                {isUserVerified && hasTalentNetwork && (
                  <Box my={4} mx="auto" p={3} bg="white" width={[1]}>
                    {!alreadyIntroduced && (
                      <>
                        <Text fontSize={2} fontWeight="semibold" mb={2}>
                          Request an introduction
                        </Text>
                        <Text color="text.main" fontSize={2}>
                          You&apos;re able to request an intro to {company.name} through the {network.name} network.
                        </Text>
                        <Button
                          type="button"
                          variant="primary"
                          mt={3}
                          px={3}
                          py={2}
                          onClick={requestIntroduction}
                          data-testid="request-introduction-button"
                          fontSize={2}
                        >
                          Request introduction
                        </Button>
                      </>
                    )}
                    {alreadyIntroduced && (
                      <>
                        <Text fontSize={2} fontWeight="semibold" mb={2}>
                          {introductionStatusAccepted ? 'Introduction has been made' : 'Introduction Request sent'}
                        </Text>
                        {!introductionStatusAccepted && (
                          <Text color="text.main" fontSize={2}>
                            We have reached out to the {company.name} team and will make the introduction if they
                            accept.
                          </Text>
                        )}
                      </>
                    )}
                  </Box>
                )}
                <Flex flexDirection="column" sx={{ rowGap: '16px', mt: showOptions ? '0px' : '40px', mb: '40px' }}>
                  {company?.locations?.length > 0 && (
                    <InfoItem title="Locations">{company.locations.map((loc) => loc.name).join(' · ')}</InfoItem>
                  )}
                  {hasIndustryTagsVisible && (
                    <InfoItem capitalize title="industry">
                      {company.industryTags.join(' · ')}
                    </InfoItem>
                  )}
                  {companySize && <InfoItem title="Size">{companySize} employees</InfoItem>}
                  {hasStageTagsVisible && <InfoItem title="Stage">{companyStageFormatter(company.stage)}</InfoItem>}
                  {company.founded && <InfoItem title="founded in">{company.founded}</InfoItem>}
                  {visibleTopics.length > 0 && (
                    <Flex flexDirection="row" sx={{ rowGap: '4px' }} flexWrap="wrap">
                      {visibleTopics.map((topic) => (
                        <Tag key={topic} variant="transparent">
                          {topic}
                        </Tag>
                      ))}
                    </Flex>
                  )}

                  {showSocialLinks && (
                    <SocialLinks
                      height="24px"
                      angellistUrl={company.angellistUrl}
                      crunchbaseUrl={company.crunchbaseUrl}
                      facebookUrl={company.facebookUrl}
                      instagramUrl={company.instagramUrl}
                      linkedinUrl={company.linkedinUrl}
                      twitterUrl={company.twitterUrl}
                    />
                  )}
                </Flex>
                {company.description && (
                  <Box as="p" lineHeight="22.4px" fontSize="16px" color="text.main" mb="40px">
                    {company.description}
                  </Box>
                )}
                {claimOrganizationProfile && company.domain && (
                  <>
                    <Box pt={3} mb={3} as="hr" variant="hr" />
                    <Text as="h4" color="text.dark" fontSize={2} fontWeight="body" mb={2}>
                      Is this your {organizationString}?
                    </Text>
                    {hasTalentNetwork && (
                      <Text mb={2}>
                        If you&apos;re responsible for hiring at this {organizationString}, make sure your{' '}
                        {organizationString} and job information are always up to date to attract top talent from the{' '}
                        {network.name} network.
                      </Text>
                    )}
                    {!hasTalentNetwork && (
                      <Text mb={2}>
                        If you&apos;re responsible for hiring at this {organizationString}, make sure your{' '}
                        {organizationString} and job information are always up to date to attract top talent from the{' '}
                        {network.name} network.
                      </Text>
                    )}
                    <Box>
                      <Button
                        as="a"
                        className="theme_only"
                        href={`https://www.getro.com/app/claim-your-profile/${network.slug}/company/${company.slug}`}
                        target="_blank"
                        onClick={trackClaimYourProfileClick}
                        variant="anchorLink"
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        color="customPrimary"
                        sx={{ textDecoration: 'none' }}
                      >
                        <Text lineHeight="1.4" fontSize="14px">
                          Claim your profile now
                        </Text>
                        <Box as={ChevronRight} width="16px" height="16px" ml={1} />
                      </Button>
                    </Box>
                    <Box mt="40px" mb={3} as="hr" variant="hr" />
                  </>
                )}
                <Button
                  as="a"
                  className="theme_only"
                  pt={1}
                  mb={[5, 5, 0]}
                  fontSize={1}
                  color="text.subtle"
                  variant="anchorLink"
                  alignItems="center"
                  display="flex"
                  sx={{ textDecoration: 'none', fontSize: '13px', lineHeight: '16px', color: 'text.subtle' }}
                  href={`https://getro-forms.typeform.com/to/TvnFzL?companyname=${company.slug}&networkname=${network.slug}`}
                  target="_blank"
                  onClick={() => {
                    dispatch(
                      flagWrongDataClicked({
                        network,
                        organizationId: company.id,
                        organizationName: company.name,
                      }),
                    );
                  }}
                >
                  <Box
                    sx={{ stroke: 'text.subtle', flexShrink: 0 }}
                    mr={2}
                    data-item="clear-icon"
                    height="16px"
                    width="16px"
                    strokeWidth="1.5"
                    aria-hidden="true"
                    as={AlertTriangle}
                  />
                  Something looks off?
                </Button>
              </Flex>
            </Box>
            <Text color="text.dark" display={['block', 'block', 'none']} fontSize={3} mb={3}>
              Open jobs at {company.name}
            </Text>
            {company.hasActiveJobs ? (
              <Box data-testid="job-list" width={[1, 1, 3 / 4]} pl={[0, 0, 3]} pr={0}>
                <Flex flexDirection="column">
                  <JobListFilters
                    network={network}
                    company={company}
                    my={4}
                    defaultQuery={defaultQuery}
                    filterOptions={filterOptions}
                    searchPlaceholder="Search by title or keyword"
                  />
                  <Box mt={3}>
                    <JobsResultsList
                      network={network}
                      canDiscard={hasTalentNetwork}
                      canFavorite={hasTalentNetwork}
                      defaultQuery={defaultQuery}
                      jobFunctions={jobFunctions}
                      simplifiedResults
                    />
                  </Box>
                </Flex>
              </Box>
            ) : (
              <Flex alignItems="center" flexDirection="column" py={[3, 4, 4]} px={[4, 5, 5]}>
                <Text color="black" fontSize={[1, 2, 3]} pb={3}>
                  This company does not have jobs relevant to this job board at this time.
                </Text>
                <Flex flexDirection="row">
                  <Text fontSize={[1, 1, 2]}>
                    To view all their jobs, visit their&nbsp;
                    <Link
                      color="text.main"
                      fontSize={[1, 1, 2]}
                      href={company.websiteUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      website
                    </Link>
                    .
                  </Text>
                </Flex>
              </Flex>
            )}
          </>
        </>
      )}
      {!company && <FourOFour network={network} />}
    </Flex>
  );
};

Index.propTypes = {
  protocol: ProtocolSchema.isRequired,
  host: PropTypes.string.isRequired,
  company: CompanySchema,
  network: NetworkSchema.isRequired,
};

Index.defaultProps = {
  company: null,
};

Index.Layout = DetailsLayout;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (ctx) => {
  const { req } = ctx;
  const { network, advancedDesign } = req;
  if (!ctx.params) {
    SentryAPI.trackError('ctx.params not defined'.ctx);
    return { props: { network, advancedDesign }, notFound: true };
  }
  const { companySlug } = ctx.params;

  const baseQuery = ctx.query;
  const query = decryptQuery(baseQuery);
  const { protocol, host } = getSSRProtocolAndHost(ctx);

  let company;
  if (network?.id) {
    company = await GetroClient.getCompanyDetails({ network, company: { slug: companySlug } });
  }

  if (!company) {
    store.dispatch(END);
    await store.sagaTask.toPromise();
    return { props: { network, advancedDesign }, notFound: true };
  }

  query['organization.slug'] = [companySlug];

  store.dispatch(END);
  await store.sagaTask.toPromise();
  return { props: { protocol, host, company, companySlug } };
});

export default Index;
